


























































































































































































// 支付结果跳转页面(支付成功/失败)
import { Context } from '@nuxt/types';
import Vue from 'vue';
import { ResponseCode } from '~/util/constant';
import FairTag from '../../components/Goods/fairTag.vue';
import FairItem from '../home/component/FairItem.vue';

let timer: any = null;
export default Vue.extend<any, any, any>({
  components: {
    FairTag,
    FairItem,
  },
  name: 'Result',
  layout: 'defaultVisa',
  asyncData(ctx: Context) {
    const lang = ctx.query.lang;
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    const { status } = ctx.params;
    const { id } = ctx.query;
    return {
      lang,
      langApi,
      status,
      id,
    };
  },
  data() {
    return {
      status: '',
      mode: 'visa',
      seconds: 10,
      detail: {},
      exhibitionList: [],
      isFocus: false,
      isHot: false,
      cooperateTypeList: [
        {
          label: '酒店预订',
          url: 'https://dcdn-jufair.jufair.com/service/apply_jiudian.jpeg',
          p1: '全网低价',
          p2: '安心订',
          p3: '放心住',
          src: this.$config.HOTEL,
        },
        // {
        //   label: '机票预订',
        //   url: 'https://dcdn-jufair.jufair.com/service/apply_jipiao.jpeg',
        //   p1: '便捷出行',
        //   p2: '即时出票',
        // },
        // {
        //   label: '签证办理',
        //   url: 'https://dcdn-jufair.jufair.com/service/apply_qianzheng.jpeg',
        //   p1: '加急办理',
        //   p2: '专业客服',
        //   src: '/visa',
        // },
        // {
        //   label: '行程定制',
        //   url: 'https://dcdn-jufair.jufair.com/service/apply_xingcheng.jpeg',
        //   p1: '商务行程',
        //   p2: '个性定制',
        //   p3: '贴心服务',
        // },
      ],
    };
  },
  computed: {
    icon() {
      if (this.status === 'success') {
        return require('~/assets/images/success.png');
      } else {
        return require('~/assets/images/error.png');
      }
    },
    desc() {
      if (this.status === 'success') {
        return `支付成功`;
      } else {
        return '非常抱歉，支付失败。请您重新下单或联系客服处理。';
      }
    },
  },
  created() {
    const { id } = this.$route.query;
    this.fetchDetail(id);
  },
  beforeDestroy() {
    if (timer) {
      clearInterval(timer);
    }
  },
  methods: {
    // 获取签证详情
    async fetchDetail(id: string | number) {
      const res: any = await this.$axios.post('/api/visaInfoApi/get', {
        id: id,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.detail = res.data;
        this.fetchVisa();
      }
    },
    async fetchVisa() {
      const res: any = await this.$axios.post('/api/exhibition/list', {
        area: this.detail.areaId,
        pageNum: 1,
        pageSize: 4,
        regionType: '1',
        selectTimeType: 1,
        locale: this.langApi,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.exhibitionList = res.data.records;
        console.log(this.exhibitionList);
      }
    },
    goHotel() {
      window.open(`${this.$config.HOTEL}`, '_self');
    },
    goRouter(src: string) {
      if (src) {
        if (src === this.$config.HOTEL) {
          window.open(`${this.$config.HOTEL}`, '_self');
        } else {
          this.$router.push({
            path: src,
          });
        }
      } else {
        const { id }: any = this.$route.query;
        window.open(`/exhibition/apply/${id}.html?success=success`, '_blank');
      }
    },
  },
  head(): Record<string, any> {
    return {
      title: `${this.status === 'success' ? '预订成功' : '预订失败'}-聚展`,
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
