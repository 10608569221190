

















































































































































































































































































import Vue from 'vue';
import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
import { TypeVisa, TypeSetMeal } from './type';
import { visaTypeOption, bizTypeOption } from './helper';
import { randomServiceList } from './service';

export default Vue.extend<any, any, any>({
  // components: {},
  filters: {
    getContentText(val: string): string {
      var reg = /<[^>]+>/gi;
      // 过滤html标签
      let str = val ? val.replace(reg, '') : '';
      return str;
    },
    firstLink(val: string): string {
      const [areaId] = val.split('-').slice(1, 1);

      if (areaId === '0') {
        return '/visa/';
      } else {
        return val;
      }
    },
  },
  // 校验params参数
  // validate(ctx: Context) {
  //   const { path } = ctx.route;
  //   console.log(122, path);
  //   if (path === '/visa' || path === '/visa/') {
  //     return true;
  //   }
  //   // const _validate = /information-(\d+)-(\d+)-(\d+)/.test(path);
  //   // return _validate;
  // },
  async asyncData(ctx) {
    // console.log(129001, ctx.params);
    // console.log(130, ctx.query);
    // const { page = '1', areaId = '' } = ctx.query;
    const {
      visaAreaId = 0,
      areaId = 0,
      type = 0,
      bizType = 0,
      page = '1',
    } = ctx.params;
    // console.log(322, visaAreaId, areaId, type, bizType);
    const pageNum = isNaN(+page) ? 1 : +page;
    const pageSize = 12;

    const listParams = {
      visaPlace: visaAreaId != 0 ? visaAreaId : null,
      areaId: areaId != 0 ? areaId : null,
      type: type != 0 ? type : null,
      bizType: bizType != 0 ? bizType : null,
      name: '',
      pageNum,
      pageSize,
    };

    // 国家,送签地,签证列表
    const result: any[] = await Promise.all([
      ctx.$axios.post('/api/visaInfoApi/allPlace'),
      ctx.$axios.post('/api/visaInfoApi/visaPlace'),
      ctx.$axios.post('/api/visaInfoApi/search', listParams),
    ]);
    const [areaResult, visaPlace, visaListResult] = result;
    let countryResult: any = [];
    areaResult.data.map((item: any) => {
      countryResult.push(...item.areaList);
    });
    let countryList = countryResult;
    let visaPlaceList: any = [];
    visaPlace.data.map((item: any) => {
      Object.keys(item).forEach((i) => {
        visaPlaceList.push({ id: i, label: item[i] });
      });
    });

    let visa: any = {};
    if (visaListResult.code === '1') {
      visa = visaListResult.data;
      if (pageNum > 1 && pageNum > visaListResult.data.pages) {
        return ctx.error({
          message: 'Page not found',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }
    }
    if (visa && visa.records) {
      visa.records.map((item: TypeVisa) => {
        let originalPriceList: any = [];
        let priceList: any = [];
        // console.log(175, item.visaPackages);
        item.visaPackages.map((i: TypeSetMeal) => {
          if (i.originalPrice) originalPriceList.push(i.originalPrice);
          priceList.push(i.price);
        });

        item['mainPic'] = item.pic ? item.pic.split(',')[0] : '';
        item['minOriginalPrice'] = originalPriceList.sort(
          (a: number, b: number) => a - b
        )[0];
        item['minPrice'] = priceList.sort((a: number, b: number) => a - b)[0];
        item['tagList'] = item.tag ? item.tag.split(',') : [];
      });
    }
    return {
      visaAreaId,
      areaId,
      type,
      bizType,
      pageNum,
      pageSize,
      visa,
      countryList,
      visaPlaceList,
    };
  },
  data(): Record<string, any> {
    return {
      randomServiceList,
      visaTypeOption,
      bizTypeOption,
      tags: ['不支持多选'],
      pageNum: 1,
      showAllArea: false,
      countryList: [],
      listQuery: {
        areaId: '',
        visaAreaId: '',
        type: '',
        bizType: '',
        name: '',
        pageSize: 1,
        pageNum: 10,
      },
    };
  },
  mounted() {
    // console.log(296, this.$route.params);
    let {
      visaAreaId = '',
      areaId = '',
      type = '',
      bizType = '',
    } = this.$route.params;
    this.listQuery.visaAreaId = visaAreaId;
    this.listQuery.areaId = areaId;
    this.listQuery.type = type;
    this.listQuery.bizType = bizType;
  },
  methods: {
    // 获取列表
    async handleGetList() {
      const { pageNum, pageSize } = this;
      const ticketRes: any = await this.$axios.post('/api/visaInfoApi/search', {
        status: this.ETabStatus[this.activeType],
        pageNum,
        pageSize,
      });

      if (ticketRes.code === ResponseCode.SUCCESS) {
        ticketRes.data.records.map((item: any) => {
          item['visaMainPic'] = item.visaPic ? item.visaPic.split(',')[0] : '';
        });
        this.ticket = ticketRes.data;
      }
    },
    handleVisaArea(id: string) {
      const {
        visaAreaId = 0,
        areaId = 0,
        type = 0,
        bizType = 0,
      } = this.$route.params;
      this.listQuery.visaAreaId = id;

      this.$router.push({
        path: `/visa-${id}-${areaId}-${type}-${bizType}-1/`,
        query: this.$route.query,
      });
    },
    handleVisaType(id: string) {
      const {
        visaAreaId = 0,
        areaId = 0,
        type = 0,
        bizType = 0,
      } = this.$route.params;
      this.listQuery.type = id;

      this.$router.push({
        path: `/visa-${visaAreaId}-${areaId}-${id}-${bizType}-1/`,
        query: this.$route.query,
      });
    },
    handleVisaBizType(id: string) {
      const {
        visaAreaId = 0,
        areaId = 0,
        type = 0,
        bizType = 0,
      } = this.$route.params;
      this.listQuery.bizType = id;

      this.$router.push({
        path: `/visa-${visaAreaId}-${areaId}-${type}-${id}-1/`,
        query: this.$route.query,
      });
    },
    handleFilterArea(id: string) {
      const {
        visaAreaId = 0,
        areaId = 0,
        type = 0,
        bizType = 0,
      } = this.$route.params;
      this.listQuery.areaId = id;

      this.$router.push({
        path: `/visa-${visaAreaId}-${id}-${type}-${bizType}-1/`,
        query: this.$route.query,
      });
    },

    // 翻页
    changePager(page: number) {
      const { visaAreaId = 0, areaId = 0, type = 0, bizType = 0 } = this;
      console.log(504, '翻页', visaAreaId);
      // ${visaAreaId}-${areaId}-${type}-${bizType}
      this.$router.push({
        path: `/visa-${visaAreaId}-${areaId}-${type}-${bizType}-${page}/`,
        query: this.$route.query,
      });
    },
    getList() {},
    handleClick(item: any) {
      let path = `/visa/${item.id}.html`;
      if (item.id === '0') {
        path = '/visa/';
      }
      this.$router.push({
        path,
      });
    },
    handleAreaMore(): void {
      this.showAllArea = !this.showAllArea;
    },
  },
  head() {
    const { pageNum } = this.$data;
    return {
      title: `签证${
        pageNum > 1 ? `_第${pageNum}页` : ''
      }-办理-材料-价格-时间-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: `聚展网`,
        },
      ],
    };
  },
});
